<template>
  <section class="our_promo">
    <div class="our_promo__promo-img">
      <img src="@/assets/images/promo_materials_img.png" />
    </div>

    <div class="our_promo-content">
      <h1 class="title">{{ $t("Promo Materials") }}</h1>
      <h1 class="sub-title">{{ $t("Our Promo materials") }}</h1>
      <p class="desc">
        {{ $t("promo_materials_desc1") }}
      </p>
      <a
        target="_blank"
        class="button button_lg button_gre button_center btn-colour"
        :href="$t('promo_download_url')"
      >
        <span class="button__inner">
          <span class="button__icon">
            <svgIcon
              className="icon-share-download"
              icon="icon-share-download"
            ></svgIcon>
          </span>
          <span class="button__text">{{ $t("download All promo") }}</span>
        </span>
      </a>
    </div>
  </section>
</template>

<script>
import svgIcon from "@/components/svg.vue";
export default {
  components: { svgIcon },
};
</script>

<style scoped lang="less">
.title{
  font-size: 35px;
  font-weight: normal;
  color: #C2F6E6;
  @media (max-width:768px) {
    font-size: 18px;
  }
}
.sub-title{
  font-size: 28px;
  color: #C2F6E6;
  font-weight: normal;
  @media (max-width:768px) {
    font-size: 15px;
  }
}
.desc{
  color: #67AA96;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  margin: 0 0 30px;
  @media (max-width:768px) {
    font-size: 14px;
  }
}
.our_promo {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.our_promo-content {
  max-width: 60%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.our_promo .btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.our_promo .btn img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.our_promo .btn span {
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  text-transform: uppercase;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.our_promo__promo-img img {
  position: absolute;
  width: 165%;
  max-width: 165%;
  left: -30%;
  top: -25%;
}
.our_promo__promo-img {
  --width:519px;
  width: var(--width);
  height: calc(var(--width) * 0.71);
  pointer-events: none;
  flex-shrink: 0;
  position: relative;
  @media (max-width: 768px) {
    --width: 200px;
    margin-right: -60px;
    margin-left: -30px;
  }
  @media (max-width: 370px) {
    display: none;
  }
}
</style>
